
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
/* CONSTANTS */
/* INTERFACES */
/* FUNCTIONS */
/* COMPONENTS */
import SvgIcon from '@/components/utilities/SvgIcon.vue';
import { showToast } from '@/utils/global-functions';
import { REMITTANCE_TYPES } from '@/modules/remittance/constants/remittance.constants';
import CodeInput from 'vue-verification-code-input';
import VuePhoneNumberInput from 'vue-phone-number-input';
import { searchFilter } from '@/utils/filter.functions';
import { countries, verifLevels } from '@/store/namespaces';
import CountryMethods from '@/store/countries/methods/countries.methods';
import VerifLevelMethods from '@/store/verificationLevels/methods/verificationLevels.methods';
import { State } from '@/modules/countries/interfaces/states.interface';
import { SaveExtraInfoThirdModal } from '@/modules/verificationLevels/interfaces/requests.interface';
import Toast from '@/components/utilities/Toast.vue';
import {
    Answer,
    Question,
} from '@/modules/verificationLevels/interfaces/questions.interface';

@Component({
    components: {
        SvgIcon,
        CodeInput,
        VuePhoneNumberInput,
        Toast,
    },
})
export default class WelcomePopup extends Vue {
    @Prop() showModal!: boolean;
    @Prop() noClose?: boolean;
    @Prop() userData!: any;
    @Prop() xColor!: string;
    show: boolean = this.showModal ? this.showModal : false;
    inputDisabled = false;
    mainTranslations: any = {
        countrySelectorLabel: 'Código país',
        countrySelectorError: 'Elige un país',
        phoneNumberLabel: '',
        example: 'Ejemplo :',
    };
    dropdownCountries: any = [];
    stateSearch = this.userData.state_name || '';
    disableContinueButton = false;
    loading: boolean = false;
    monthlyAmounts = [];
    workIndustries = [];
    selectedWorkIndustry = '';
    selectedMonthlyAmount = '';
    isOtherOption = false;

    /* MODAL */
    @Watch('showModal')
    async renderModal() {
        this.show = this.showModal;
    }

    @Watch('show')
    sendToParent(newVal: boolean) {
        this.$emit('changeModalStatus', newVal);
    }

    get mainPhoneCountryCode() {
        return this.userData.iso_code_resid_country || 'CO';
    }

    get stateCriteria() {
        return this.stateSearch.trim().toLowerCase();
    }

    get availableStateOptions() {
        const stateCriteria = this.stateCriteria;
        if (stateCriteria) {
            return searchFilter(
                this.states,
                ['state_name'],
                this.stateCriteria
            );
        }
        return this.states;
    }

    get searchDesc() {
        if (this.stateCriteria && this.availableStateOptions.length === 0) {
            return 'No se encontraron coincidencias';
        }
        return '';
    }

    get continueButton() {
        return (
            this.selectedWorkIndustry !== '' &&
            this.selectedWorkIndustry !== undefined &&
            this.selectedMonthlyAmount !== '' &&
            this.selectedMonthlyAmount !== undefined
        );
    }

    get workIndustryDropdownText() {
        let text = this.selectedWorkIndustry;
        if (!text && !this.isOtherOption) {
            text = 'Seleccione una opción';
        } else if (this.isOtherOption) {
            text = 'Otros.';
        }
        return text;
    }

    async submit() {
        console.log('SUBMIT userData', this.userData);
        this.loading = true;
        await this.saveExtraInfoThirdModal({
            id_user: this.userData.id_user,
            industry: this.selectedWorkIndustry,
            salary_range: this.selectedMonthlyAmount,
        });

        this.loading = false;
        this.showModal = false;
        showToast(
            'Información validada correctamente. Continúe con su remesa.',
            'success'
        );
    }

    async mounted() {
        console.log('this.userData', this.userData);
        this.fetchStatesByResidCountry(this.userData.id_resid_country);
        this.dropdownCountries.push(this.userData.iso_code_resid_country);
        await this.fetchQuestions(this.userData.id_resid_country);
        this.monthlyAmounts = this.levelQuestions[0].answers; // Cambiar
        this.workIndustries = this.levelQuestions[1].answers; // Cambiar
    }

    selectWorkIndustry(answer: Answer) {
        if (answer.answer === 'Otros.') {
            this.selectedWorkIndustry = '';
            this.isOtherOption = true;

            // Usar setTimeout para retrasar el enfoque
            this.$nextTick(() => {
                setTimeout(() => {
                    const inputElement = this.$refs
                        .otherOptionInput as HTMLInputElement;
                    if (inputElement) {
                        inputElement.focus();
                    } else {
                        console.log('El input no está disponible en el DOM.');
                    }
                }, 100); // Pequeño retraso de 100ms
            });
        } else {
            this.selectedWorkIndustry = answer.answer;
            this.isOtherOption = false;
        }
    }

    selectMonthlyAmount(answer: Answer) {
        this.selectedMonthlyAmount = answer.answer;
    }

    /* END MODAL */

    @countries.Action(
        CountryMethods.actions.FETCH_COUNTRY_STATES_BY_RESID_COUNTRY
    )
    fetchStatesByResidCountry!: (
        countryId: number | string
    ) => Promise<boolean>;
    @countries.Getter(CountryMethods.getters.GET_COUNTRY_STATES)
    states!: State[];
    @verifLevels.Action(VerifLevelMethods.actions.SAVE_EXTRA_INFO_THIRD_MODAL)
    saveExtraInfoThirdModal!: (
        data: SaveExtraInfoThirdModal
    ) => Promise<boolean>;
    @verifLevels.Getter(VerifLevelMethods.getters.GET_LEVELS_QUESTIONS)
    levelQuestions!: Question[];
    @verifLevels.Action(VerifLevelMethods.actions.FETCH_LEVELS_QUESTIONS)
    fetchQuestions!: (idResidCountry: number) => Promise<boolean>;
}
